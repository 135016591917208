// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

// Guest Views
import Login from '@/views/Login.vue';
import RegisterPage from '@/views/Register.vue';
import VerifyUser from '@/views/VerifyUser.vue';
import WelcomePage from '@/views/WelcomePage.vue';

// Seller Views
import SellerDashboard from '@/views/Sellers/Dashboard.vue';
import MySellerProfile from '@/views/Sellers/SellerPersonalData.vue';
import MyItems from '@/views/Sellers/MyItems.vue';
import SearchBazaars from '@/views/Sellers/SearchBazaars.vue';

// Organizer Views
import OrganizerDashboard from '@/views/Organizers/Dashboard.vue';
import MyOrganizerProfile from '@/views/Organizers/OrganizerPersonalData.vue';
import ManageBazaars from '@/views/Organizers/ManageBazaars.vue';
import Reports from '@/views/Organizers/Reports.vue';

const routes = [
  { path: '/', name: 'Welcome', component: WelcomePage },
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: RegisterPage },
  { path: '/verify', name: 'VerifyUser', component: VerifyUser },
  // Seller Routes
  { path: '/sellers/dashboard', name: 'SellerDashboard', component: SellerDashboard, meta: { role: 'seller' } },
  { path: '/sellers/my-seller-profile', name: 'MySellerProfile', component: MySellerProfile, meta: { role: 'seller' } },
  { path: '/sellers/my-items', name: 'MyItems', component: MyItems, meta: { role: 'seller' } },
  { path: '/sellers/search-bazaars', name: 'SearchBazaars', component: SearchBazaars, meta: { role: 'seller' } },
  // Organizer Routes
  { path: '/organizers/dashboard', name: 'OrganizerDashboard', component: OrganizerDashboard, meta: { role: 'organizer' } },
  { path: '/organizers/my-organizer-profile', name: 'MyOrganizerProfile', component: MyOrganizerProfile, meta: { role: 'organizer' } },
  { path: '/organizers/manage-bazaars', name: 'ManageBazaars', component: ManageBazaars, meta: { role: 'organizer' } },
  { path: '/organizers/reports', name: 'Reports', component: Reports, meta: { role: 'organizer' } },
  // Catch-all route
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const userRole = store.getters.getUserRole;

  if (to.meta.role && to.meta.role !== userRole) {
    return next('/login'); // Redirect to login if roles don't match
  }

  next();
});

export default router;
