<template>
  <div>
    <div class="container">
      <h1>Verkäufer-Dashboard</h1>
      <p>Willkommen im Verkäufer-Dashboard!</p>
    </div>
  </div>
</template>

<script>

export default {
};
</script>
