import { API_BASE } from '../config';

// src/store/index.js
import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()], // Persist state across sessions
  state: {
    authToken: '', // JWT for authentication
    role: 'guest', // Default user role
  },
  getters: {
    getAuthToken(state) {
      return state.authToken;
    },
    getUserRole(state) {
      return state.role;
    },
  },
  mutations: {
    setAuthToken(state, token) {
      state.authToken = token;
    },
    setUserRole(state, role) {
      state.role = role;
    },
    clearAuth(state) {
      state.authToken = '';
      state.role = 'guest';
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        const response = await fetch(`${API_BASE}/login.php`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password }),
        });
        const data = await response.json();
        if (data.success) {
          commit('setAuthToken', data.token);
          commit('setUserRole', data.role);
          return data;
        } else {
          throw new Error(data.message || 'Login failed');
        }
      } catch (error) {
        console.error('Login error:', error.message);
        throw error;
      }
    },
    async logout({ commit }) {
      commit('clearAuth'); // Clear token and role
    },
  },
});
