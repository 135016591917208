<template>
  <div>
    <h1>Reports</h1>
    <p>View sales summaries and other reports here.</p>
  </div>
</template>

<script>
export default {
  name: 'Reports',
};
</script>
