<template>
  <div class="container text-center">
  <h1>Willkommen bei Basareno</h1>
    <div class="row">
      <div class="col mt-4">
        
        <p>Bitte melden Sie sich an, wenn Sie einen Basar veranstalten oder als Verkäufer auf eionem Basar teilnehmen möchten.</p>
        <p>Sie haben noch kein Konto? Kein Problem. Die Registrierung ist für Verkäufer und Veranstalter kostenlos.</p>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col text-center">
        <router-link to="/login" class="btn btn-primary">Anmeldung</router-link>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col text-center">
        <router-link to="/register" class="btn btn-success">Registrierung</router-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'WelcomePage',
};
</script>

<style scoped>
h1 {
  margin-top: 20px;
}

p {
  margin-top: 10px;
}

.btn {
  padding: 10px 20px;
}
</style>
