import { API_BASE } from '../config';

// Fetch items
export const fetchItems = async (authToken) => {
    try {
      const response = await fetch(`${API_BASE}/getItems.php`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
  
      const result = await response.json();
  
      if (!response.ok) {
        throw new Error("Failed to fetch items.");
      }
  
      return result; // { success: true, items: [...] }
    } catch (error) {
      console.error("Error fetching items:", error.message);
      throw error;
    }
  };

// Save an item
export const saveItem = async (authToken, formData) => {
    try {
      const response = await fetch(`${API_BASE}/saveItem.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(formData),
      });
  
      const result = await response.json();
  
      if (!response.ok) {
        throw new Error("Failed to save the item.");
      }
  
      return result; // { success: true }
    } catch (error) {
      console.error("Error saving item:", error.message);
      throw error;
    }
  };

// Delete an item
export const deleteItem = async (authToken, id) => {
    try {
      const response = await fetch(`${API_BASE}/deleteItem.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ id }),
      });
  
      const result = await response.json();
  
      if (!response.ok) {
        throw new Error("Failed to delete the item.");
      }
  
      return result; // { success: true }
    } catch (error) {
      console.error("Error deleting item:", error.message);
      throw error;
    }
  };