import { API_BASE } from '../config';
import database from './database';

// PHP Endpoint API requests

export const createBazaar = async (authToken, bazaarData) => {
  try {
    const response = await fetch(`${API_BASE}/createBazaar.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(bazaarData),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Fehler beim Erstellen des Basars.');
    }

    return result; // { success: true, bazaarId: <id> }
  } catch (error) {
    console.error('Error creating bazaar:', error.message);
    throw error;
  }
};

// Update a bazaar
export const updateBazaar = async (authToken, formData) => {
  try {
    const response = await fetch(`${API_BASE}/updateBazaar.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(formData),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error("Failed to update the bazaar.");
    }

    return result; // { success: true }
  } catch (error) {
    console.error("Error updating bazaar:", error.message);
    throw error;
  }
};

// src/api/fetchBazaars.js
export const fetchBazaars = async (authToken) => {
  try {
    const response = await fetch(`${API_BASE}/getBazaars.php`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Fehler beim Abrufen der Basare.');
    }

    return result; // { success: true, bazaars: [...] }
  } catch (error) {
    console.error('Error fetching bazaars:', error.message);
    throw error;
  }
};
// Delete a bazaar
export const deleteBazaar = async (authToken, id) => {
  try {
    const response = await fetch(`${API_BASE}/deleteBazaar.php`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ id }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Fehler beim Löschen des Basars.');
    }

    return result; // { success: true }
  } catch (error) {
    console.error('Error deleting bazaar:', error.message);
    throw error;
  }
};

// Fetch sellers in a specific bazaar
export const fetchSellersInBazaar = async (authToken, bazaar_id) => {
  try {
    const response = await fetch(`${API_BASE}/getSellersInBazaar.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ bazaar_id }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error("Failed to fetch sellers in the bazaar.");
    }

    return result; // { success: true, sellers: [...] }
  } catch (error) {
    console.error("Error fetching sellers in bazaar:", error.message);
    throw error;
  }
};

// Fetch items in a specific bazaar
export const fetchItemsInBazaar = async (authToken, bazaar_id) => {
  try {
    const response = await fetch(`${API_BASE}/getItemsInBazaar.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ bazaar_id }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error("Failed to fetch items in the bazaar.");
    }

    return result; // { success: true, items: [...] }
  } catch (error) {
    console.error("Error fetching items in bazaar:", error.message);
    throw error;
  }
};

// Toggle registration status for a bazaar
export const toggleRegistration = async (authToken, id, registration_closed) => {
  try {
    const response = await fetch(`${API_BASE}/toggleRegistration.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ id, registration_closed }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || "Failed to toggle registration for the bazaar.");
    }

    return result; // { success: true, message: "..." }
  } catch (error) {
    console.error("Error toggling registration for bazaar:", error.message);
    throw error;
  }
};

  
// View Sellers in a Bazaar
export const viewSellers = async (authToken, bazaar_id) => {
  try {
    const response = await fetch(`${API_BASE}/getSellersInBazaar.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ bazaar_id }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error("Failed to view sellers in the bazaar.");
    }

    return result; // { success: true, sellers: [...] }
  } catch (error) {
    console.error("Error viewing sellers in bazaar:", error.message);
    throw error;
  }
};

// View Report for a Bazaar
export const viewReport = async (authToken, bazaar_id) => {
  try {
    const response = await fetch(`${API_BASE}/getItemsInBazaar.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ bazaar_id }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error("Failed to view the report for the bazaar.");
    }

    return result; // { success: true, report: [...] }
  } catch (error) {
    console.error("Error viewing report for bazaar:", error.message);
    throw error;
  }
};
  
