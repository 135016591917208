<template>
  <div class="container d-flex flex-column align-items-center justify-content-center">
    <h1 class="mb-4 text-center">Anmeldung</h1>
    <form @submit.prevent="handleLogin" class="w-100" style="max-width: 400px;">
      <!-- Email Field -->
      <div class="mb-3">
        <label for="email" class="form-label">E-Mail:</label>
        <input 
          id="email" 
          v-model="email" 
          type="email" 
          class="form-control text-center" 
          placeholder="Geben Sie Ihre E-Mail-Adresse ein"
          required 
        />
      </div>

      <!-- Password Field -->
      <div class="mb-3">
        <label for="password" class="form-label">Passwort:</label>
        <input 
          id="password" 
          v-model="password" 
          type="password" 
          class="form-control text-center" 
          placeholder="Geben Sie Ihr Passwort ein"
          required 
        />
      </div>

      <!-- Submit Button -->
      <div class="d-grid">
        <button type="submit" class="btn btn-primary">Anmelden</button>
      </div>
    </form>

    <!-- Message Modal -->
    <MessageModal
      ref="loginModal"
      :title="modalTitle"
      :message="modalMessage"
      :alertType="modalAlertType"
      confirmText="OK"
      :showCancel="false"
      @confirm="closeModal"
    />
  </div>
</template>


<script>
import MessageModal from "@/components/MessageModal.vue";

export default {
  components: {
    MessageModal,
  },
  data() {
    return {
      email: "",
      password: "",
      modalTitle: "",
      modalMessage: "",
      modalAlertType: "info", // Default alert type
      showModal: false, // Define and initialize showModal
      closeModal: false,
    };
  },
  methods: {
    async handleLogin() {
      try {
        const result = await this.$store.dispatch('login', {
          email: this.email,
          password: this.password,
        });

        // Redirect based on the user's role
        if (result.role === 'seller') {
          this.$router.push('/sellers/dashboard');
        } else if (result.role === 'organizer') {
          this.$router.push('/organizers/dashboard');
        } else {
          this.$router.push('/');
        }
      } catch (error) {
        // Configure and open the modal for error message
        this.modalTitle = 'Login Fehlgeschlagen';
        this.modalMessage = error.message || 'Login fehlgeschlagen.';
        this.modalAlertType = 'danger';
        this.$refs.loginModal.open();
      }
    },
  },
  async mounted() {
    // Ensure CSRF token is fetched when the component is mounted
    // await this.$store.dispatch('fetchCsrfToken');
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
}
label {
  margin-top: 10px;
}
button {
  margin-top: 20px;
}
</style>
