<template>
  <div class="container">
    <h1 class="my-4">Meine Artikel</h1>
    <p>Verwalten Sie hier Ihre Artikel für den Basar.</p>

    <!-- Item List -->
    <ul class="list-group">
      <li
        v-for="item in items"
        :key="item.id"
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        <div>
          <strong>{{ item.name }}</strong> ({{ item.size }}) - {{ item.price }} €
        </div>
        <div>
          <button @click="editItem(item)" class="btn btn-warning btn-sm me-2">
            Bearbeiten
          </button>
          <button @click="deleteItem(item.id)" class="btn btn-danger btn-sm">
            Löschen
          </button>
        </div>
      </li>
    </ul>

    <!-- Add Item Button -->
    <button @click="showAddItemForm" class="btn btn-primary mt-3">
      Neuen Artikel hinzufügen
    </button>

    <!-- Add/Edit Item Form -->
    <div v-if="showForm" class="mt-4">
      <h3>Artikel hinzufügen/bearbeiten</h3>
      <form @submit.prevent="saveItem">
        <div class="mb-3">
          <label for="name" class="form-label">Name</label>
          <input id="name" v-model="formData.name" type="text" class="form-control" required />
        </div>
        <div class="mb-3">
          <label for="size" class="form-label">Größe</label>
          <input id="size" v-model="formData.size" type="text" class="form-control" />
        </div>
        <div class="mb-3">
          <label for="price" class="form-label">Preis (€)</label>
          <input
            id="price"
            v-model="formData.price"
            type="number"
            class="form-control"
            required
          />
        </div>
        <button type="submit" class="btn btn-success">Speichern</button>
        <button @click="hideForm" class="btn btn-secondary">Abbrechen</button>
      </form>
    </div>
  </div>
</template>

<script>
import { 
  fetchItems, 
  saveItem, 
  deleteItem 
} from "@/api-connectors/seller";

export default {
  data() {
    return {
      items: [],
      showForm: false,
      formData: {
        id: null,
        name: "",
        size: "",
        price: 0,
        bazaar_id: 1, // Example bazaar_id
      },
    };
  },
  methods: {
    async fetchItems() {
      try {
        const authToken = this.$store.getters.getAuthToken;
        const result = await fetchItems(authToken);
        if (result.success) {
          this.items = result.items;
        } else {
          alert("Fehler beim Abrufen der Artikel.");
        }
      } catch (error) {
        console.error("Error fetching items:", error);
        alert("Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.");
      }
    },

    async saveItem() {
      try {
        const authToken = this.$store.getters.getAuthToken;
        const result = await saveItem(authToken, this.formData);
        if (result.success) {
          alert("Artikel erfolgreich gespeichert!");
          this.showForm = false;
          this.fetchItems();
        } else {
          alert("Fehler beim Speichern");
        }
      } catch (error) {
        console.error("Error saving item:", error);
        alert("Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.");
      }
    },

    async deleteItem(id) {
      try {
        const authToken = this.$store.getters.getAuthToken;
        const result = await deleteItem(authToken, id);
        if (result.success) {
          alert("Artikel erfolgreich gelöscht!");
          this.fetchItems();
        } else {
          alert("Fehler beim Löschen");
        }
      } catch (error) {
        console.error("Error deleting item:", error);
        alert("Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.");
      }
    },
    showAddItemForm() {
      this.showForm = true;
      this.formData = { id: null, name: "", size: "", price: 0, bazaar_id: 1 };
    },
    hideForm() {
      this.showForm = false;
    },
    editItem(item) {
      this.showForm = true;
      this.formData = { ...item };
    },    
  },
  mounted() {
    this.fetchItems();
  },
};
</script>
