import { API_BASE } from '../config';

// Register a new user
export const registerUser = async (authToken, formData) => {
    try {
      const response = await fetch(`${API_BASE}/register.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(formData),
      });
  
      const result = await response.json();
  
      if (!response.ok) {
        throw new Error("Failed to register the user.");
      }
  
      return result; // { success: true }
    } catch (error) {
      console.error("Error registering user:", error.message);
      throw error;
    }
  };