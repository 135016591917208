<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Basareno</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="true"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse text-center" id="navbarNav">
        <ul class="navbar-nav me-auto">
          <li v-for="item in menu" :key="item.label" class="nav-item">
            <router-link 
              class="nav-link" 
              :to="item.to" 
              @click="collapseNavbar"
            >
              {{ item.label }}
            </router-link>
          </li>
        </ul>

        <!-- Sync Status Icon -->
        <div class="sync-status ms-auto">
          <i
            :class="['bi', isOnline ? 'bi-wifi' : 'bi-wifi-off']"
            :title="isOnline ? 'Online' : 'Offline'"
          ></i>
        </div>
        <hr 
          v-if="isLoggedIn">
        </hr>
        <!-- Logout Button -->
        <button
          v-if="isLoggedIn"
          class="btn btn-danger ms-auto mb-2"
          @click="askLogout"
        >
          Abmelden
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import menus from "@/components/menus";

export default {
  data() {
    return {
      isOnline: navigator.onLine, // Initial online state
    };
  },
  computed: {
    ...mapGetters(["getUserRole"]),
    menu() {
      return menus[this.getUserRole] || menus.guest;
    },
    isLoggedIn() {
      return this.getUserRole !== "guest";
    },
  },
  methods: {
    askLogout() {
      this.collapseNavbar();
      this.$emit('showLogoutModal');
    },
    collapseNavbar() {
      // Get the navbar collapse element
      const navbarCollapse = document.getElementById("navbarNav");
      if (navbarCollapse && navbarCollapse.classList.contains("show")) {
        navbarCollapse.classList.remove("show");
      }
    },
  },  
  mounted() {
    // Listen for online/offline events
    window.addEventListener("online", () => {
      this.isOnline = true;
    });
    window.addEventListener("offline", () => {
      this.isOnline = false;
    });
  },  
  beforeUnmount() {
    // Remove event listeners
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOfflineStatus);
  },  
};
</script>
<style scoped>
.sync-status i {
  font-size: 1.5rem;
  color: white;
}
</style>