<template>
  <div
    v-if="bazaar"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    ref="modal"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">Basar Bearbeiten</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSave">
            <div class="mb-3">
              <label for="bazaarName" class="form-label">Bezeichnung</label>
              <input
                id="bazaarName"
                v-model="bazaar.bazaar_name"
                type="text"
                class="form-control"
                placeholder="z.B. Gemeindehalle Bärstadt"
                required
              />
            </div>
            <div class="mb-3">
              <label for="bazaarStreet" class="form-label">Straße</label>
              <input
                id="bazaarStreet"
                v-model="bazaar.bazaar_street"
                type="text"
                class="form-control"
                required
              />
            </div>
            <div class="mb-3">
              <label for="bazaarHousenumber" class="form-label"
                >Hausnummer</label
              >
              <input
                id="bazaarHousenumber"
                v-model="bazaar.bazaar_housenumber"
                type="text"
                class="form-control"
                required
              />
            </div>
            <div class="mb-3">
              <label for="bazaarZip" class="form-label">PLZ</label>
              <input
                id="bazaarZip"
                v-model="bazaar.bazaar_zip"
                type="text"
                class="form-control"
                required
              />
            </div>
            <div class="mb-3">
              <label for="bazaarCity" class="form-label"
                >Stadt / Gemeinde</label
              >
              <input
                id="bazaarCity"
                v-model="bazaar.bazaar_city"
                type="text"
                class="form-control"
                required
              />
            </div>
            <hr />
            <div class="mb-3">
              <label for="start_date" class="form-label">Startdatum</label>
              <input
                v-model="bazaar.start_date"
                type="date"
                id="start_date"
                class="form-control"
                required
              />
            </div>
            <div class="mb-3">
              <label for="max_sellers" class="form-label">Max. Verkäufer</label>
              <input
                v-model="bazaar.max_sellers"
                type="number"
                id="max_sellers"
                class="form-control"
                required
              />
            </div>
            <div class="mb-3">
              <label for="max_items" class="form-label"
                >Max. Artikel pro Verkäufer</label
              >
              <input
                v-model="bazaar.max_items_per_seller"
                type="number"
                id="max_items"
                class="form-control"
                required
              />
            </div>
            <div class="mb-3">
              <label for="commission" class="form-label">Kommission (%)</label>
              <input
                v-model="bazaar.commission"
                type="number"
                id="commission"
                class="form-control"
                required
              />
            </div>
            <div class="mb-3">
              <label for="starting_fee" class="form-label"
                >Startgebühr (€)</label
              >
              <input
                v-model="bazaar.starting_fee"
                type="number"
                id="starting_fee"
                class="form-control"
                required
              />
            </div>
            <div class="mb-3">
              <label for="min_item_price" class="form-label"
                >Mindestpreis (€)</label
              >
              <input
                v-model="bazaar.min_item_price"
                type="number"
                id="min_item_price"
                class="form-control"
                required
              />
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-success">Speichern</button>
              <button
                type="button"
                class="btn btn-secondary"
                @click="closeModal"
              >
                Abbrechen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  name: "EditBazaarModal",
  props: ["bazaar"],
  methods: {
    openModal() {
      const modal = new Modal(this.$refs.modal);
      modal.show();
    },
    closeModal() {
      const modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      this.$emit("close");
    },
    handleSave() {
      this.$emit("save", { ...this.bazaar });
      this.closeModal();
    },
  },
};
</script>
