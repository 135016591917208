import { API_BASE } from "../config";

export const fetchUserDetails = async (authToken) => {
  try {
    const response = await fetch(`${API_BASE}/getUserDetails.php`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Fehler beim Abrufen der Benutzerdetails.');
    }

    return result; // { success: true, userDetails: {...} }
  } catch (error) {
    console.error('Error fetching user details:', error.message);
    throw error;
  }
};


export const updateUserDetails = async (authToken, userDetails) => {
  try {
    const response = await fetch(`${API_BASE}/updateUserDetails.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(userDetails),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Fehler beim Aktualisieren der Benutzerdetails.');
    }

    return result; // { success: true }
  } catch (error) {
    console.error('Error updating user details:', error.message);
    throw error;
  }
};

// Verify a user
export const verifyUser = async (authToken, token) => {
  try {
    const response = await fetch(`${API_BASE}/verifyUser.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ token }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error("Failed to verify the user.");
    }

    return result; // { success: true }
  } catch (error) {
    console.error("Error verifying user:", error.message);
    throw error;
  }
};

