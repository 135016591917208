<template>
  <div class="container">
    <h2 class="text-center">Registrierung</h2>
    <form @submit.prevent="submitForm" class="mt-4">
    
      <div class="row g-3 mx-auto" style="max-width: 900px;">
              <!-- Role Selection -->
        <div class="col-12">
          <label class="form-label">Ich möchte:</label>
          <select v-model="formData.role" class="form-select" required>
            <option value="seller">Auf einem Basar Verkaufen</option>
            <option value="organizer">Veranstalter werden</option>
          </select>
        </div>
        <!-- Personal Data -->
        <div class="col-md-4">
          <label for="firstName" class="form-label">Vorname</label>
          <input v-model="formData.firstName" type="text" id="firstName" class="form-control" required />
        </div>
        <div class="col-md-4">
          <label for="lastName" class="form-label">Nachname</label>
          <input v-model="formData.lastName" type="text" id="lastName" class="form-control" required />
        </div>
        <div class="col-md-4">
          <label for="phoneNumber" class="form-label">Telefonnummer</label>
          <input v-model="formData.phone_number" type="text" id="phoneNumber" class="form-control" />
        </div>

        <!-- Organizer-specific fields -->
        <template v-if="formData.role === 'organizer'">
          <div class="col-md-6">
            <label for="street" class="form-label">Straße</label>
            <input v-model="formData.street" type="text" id="street" class="form-control" required />
          </div>
          <div class="col-md-3">
            <label for="houseNumber" class="form-label">Hausnummer</label>
            <input v-model="formData.houseNumber" type="text" id="houseNumber" class="form-control" required />
          </div>
          <div class="col-md-3">
            <label for="zip" class="form-label">PLZ</label>
            <input v-model="formData.zip" type="text" id="zip" class="form-control" required />
          </div>
          <div class="col-md-12">
            <label for="city" class="form-label">Stadt</label>
            <input v-model="formData.city" type="text" id="city" class="form-control" required />
          </div>
        </template>

        <div class="col-md-6">
          <label for="email" class="form-label">E-Mail</label>
          <input v-model="formData.email" type="email" id="email" class="form-control" required />
        </div> 
        <div class="col-md-6">
          <label for="password" class="form-label">Passwort</label>
          <input v-model="formData.password" type="password" id="password" class="form-control" required />
        </div>
        <div class="col-md-12">
          <label for="confirmPassword" class="form-label">Passwort bestätigen</label>
          <input v-model="formData.confirmPassword" type="password" id="confirmPassword" class="form-control" required />
        </div>

        <!-- Consent -->
        <div class="col-12">
          <div class="form-check">
            <input v-model="formData.privacyConsent" type="checkbox" id="privacyConsent" class="form-check-input" required />
            <label for="privacyConsent" class="form-check-label">
              Ich stimme den <a href="/datenschutz" target="_blank">Datenschutzbestimmungen</a> zu
            </label>
          </div>
        </div>
        <div class="col-12">
          <label class="form-label">Datenaufbewahrung</label>
          <div>
            <div class="form-check">
              <input v-model="formData.dataRetention" class="form-check-input" type="radio" value="keep" id="keepData" required />
              <label for="keepData" class="form-check-label">Daten für mindestens 4 Jahre speichern</label>
            </div>
            <div class="form-check">
              <input v-model="formData.dataRetention" class="form-check-input" type="radio" value="delete" id="deleteData" required />
              <label for="deleteData" class="form-check-label">Daten nach dem Basar löschen</label>
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-primary">Registrieren</button>
        </div>
      </div>
    </form>

    <!-- Back to Top Button -->
    <button
      v-if="showBackToTop"
      id="back-to-top"
      class="btn btn-primary position-fixed"
      style="bottom: 20px; right: 20px; z-index: 1000"
      @click="scrollToTop"
    >
      <v-icon name="fa-arrow-up" />
    </button>

  </div>
</template>

<script>
import { Toast } from "bootstrap";
import {
  registerUser,
} from "@/api-connectors/register";

export default {
  data() {
    return {
      toastTitle: "",
      toastMessage: "",
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        street: "",
        houseNumber: "",
        phoneNumber: "",
        city: "",
        zip: "",
        privacyConsent: false,
        dataRetention: "",
        role: "seller",
      },
      showBackToTop: false, // Tracks visibility of the "Back to Top" button
    };
  },
  methods: {
    async submitForm() {
      if (this.formData.password !== this.formData.confirmPassword) {
        alert("Passwörter stimmen nicht überein.");
        return;
      }

      try {
        const authToken = this.$store.getters.getAuthToken;
        const result = await registerUser(authToken, this.formData);

        if (result.success) {
          alert("Registrierung erfolgreich! Bitte überprüfen Sie Ihre E-Mails.");
          this.$router.push("/login");
        } else {
          alert(result.message || "Fehler bei der Registrierung.");
        }
      } catch (error) {
        console.error("Error during registration:", error);
        alert("Ein Fehler ist aufgetreten.");
      }
    },
    showToast(title, message) {
      this.toastTitle = title;
      this.toastMessage = message;

      // Initialize and show the Bootstrap Toast
      const toastEl = this.$refs.toast;
      const toastInstance = new Toast(toastEl);
      toastInstance.show();
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    checkScroll() {
      this.showBackToTop = window.scrollY > 200;
    },    
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkScroll);
  },  
};
</script>
