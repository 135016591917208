<template>
  <div class="container">
    <h2 class="text-center">{{ message }}</h2>
    <p v-if="success" class="text-center text-success">Ihre Registrierung wurde erfolgreich bestätigt!</p>
    <p v-else class="text-center text-danger">Es gab ein Problem bei der Verifizierung. Bitte versuchen Sie es erneut.</p>
    <div class="text-center mt-3">
      <router-link v-if="success" to="/login" class="btn btn-primary">Zum Login</router-link>
    </div>
  </div>
  
</template>

<script>
import {
  verifyUser,
} from "@/api-connectors/user";

export default {
  data() {
    return {
      message: "Verifiziere Benutzer...",
      success: false,
    };
  },
  async mounted() {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (!token) {
      this.message = "Ungültige Verifizierungsdaten.";
      return;
    }

    try {
      const authToken = this.$store.getters.getAuthToken;
      const result = await verifyUser(authToken, token);

      if (result.success) {
        this.success = true;
        this.message = "Erfolg!";
      } else {
        this.message = result.message || "Fehler bei der Verifizierung.";
      }
    } catch (error) {
      console.error("Error during verification:", error);
      this.message = "Interner Fehler.";
    }
  },
};
</script>
