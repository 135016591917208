/**
 * Database.js
 * Unified abstraction layer for managing database operations across Cordova (SQLite) and browsers (IndexedDB).
 * 
 * ## Features
 * - Automatic environment detection: uses SQLite for Cordova and IndexedDB for browser environments.
 * - Supports CRUD operations: `insert`, `update`, `delete`, `select`.
 * - Automatically initializes required tables (SQLite) or object stores (IndexedDB).
 * 
 * ## Supported Tables/Object Stores
 * - `users`: Stores user data.
 * - `user_details`: Stores additional user details.
 * - `bazaars`: Stores bazaar details.
 * - `items`: Stores items associated with bazaars.
 * - `seller_numbers`: Tracks seller numbers for bazaars.
 * - `logs`: Stores action logs.
 * - `sessions`: Tracks active sessions.
 * 
 * ## Usage Examples
 * 
 * ### 1. Initialize the Database
 * Initialize the database and ensure it's ready for use:
 * 
 * ```javascript
 * import database from './database';
 * 
 * database.init().then(() => {
 *   console.log('Database initialized');
 * }).catch((err) => {
 *   console.error('Database initialization failed:', err);
 * });
 * ```
 * 
 * ### 2. Insert Data
 * Add new records to a table or object store:
 * 
 * ```javascript
 * await database.insert('users', {
 *   email: 'test@example.com',
 *   password_hash: 'hashedpassword',
 *   role: 'guest',
 * });
 * ```
 * 
 * ### 3. Update Data
 * Update an existing record:
 * 
 * ```javascript
 * await database.update('users', 1, {
 *   email: 'updated@example.com',
 *   role: 'administrator',
 * });
 * ```
 * 
 * ### 4. Delete Data
 * Remove a record by its ID:
 * 
 * ```javascript
 * await database.delete('users', 1);
 * ```
 * 
 * ### 5. Select Data
 * Query records with optional conditions:
 * 
 * ```javascript
 * const users = await database.select('users', { role: 'guest' });
 * console.log(users);
 * ```
 * 
 * ## Adding New Tables/Object Stores
 * 1. For SQLite:
 *    - Add the SQL `CREATE TABLE` statement in the `createTables(tx)` method.
 * 2. For IndexedDB:
 *    - Add the object store definition in the `initIndexedDB()` method.
 * 
 * ## Notes
 * - **Environment Detection**: The `isCordova` flag (`!!window.cordova`) is used to determine the runtime environment.
 * - **Error Handling**: Use `try-catch` blocks or `.catch()` for robust error handling during database operations.
 * 
 * ## Future Improvements
 * - Add support for more advanced queries (e.g., joins, complex conditions).
 * - Implement batch operations for better performance.
 * - Introduce caching mechanisms to minimize IndexedDB or SQLite queries.
 */


let db; // Database instance

const database = {
  db: null,
  isCordova: !!window.cordova,

  async init() {
    if (this.isCordova) {
      console.log('Using Cordova SQLite');
      this.db = window.sqlitePlugin.openDatabase({ name: 'app.db', location: 'default' });

      return new Promise((resolve, reject) => {
        this.db.transaction((tx) => {
          this.createTables(tx);
        },
        (error) => {
          console.error('SQLite initialization error:', error);
          reject(error);
        },
        () => {
          console.log('SQLite initialized successfully');
          resolve();
        });
      });
    } else {
      console.log('Using IndexedDB');
      this.db = await this.initIndexedDB();
      console.log('IndexedDB initialized successfully');
      return Promise.resolve();
    }
  },

  async initIndexedDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('app-db', 1);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;

        const stores = [
          { name: 'users', options: { keyPath: 'id', autoIncrement: true } },
          { name: 'user_details', options: { keyPath: 'id', autoIncrement: true } },
          { name: 'bazaars', options: { keyPath: 'id', autoIncrement: true } },
          { name: 'items', options: { keyPath: 'id', autoIncrement: true } },
          { name: 'seller_numbers', options: { keyPath: 'id', autoIncrement: true } },
          { name: 'logs', options: { keyPath: 'id', autoIncrement: true } },
          { name: 'sessions', options: { keyPath: 'id', autoIncrement: true } },
        ];

        stores.forEach((store) => {
          if (!db.objectStoreNames.contains(store.name)) {
            db.createObjectStore(store.name, store.options);
          }
        });

        console.log('IndexedDB schema upgraded');
      };

      request.onsuccess = () => resolve(request.result);
      request.onerror = (error) => reject(error);
    });
  },

  createTables(tx) {
    if (this.isCordova) {
      const tableDefinitions = [
        `
          CREATE TABLE IF NOT EXISTS users (
            id INTEGER PRIMARY KEY AUTOINCREMENT,
            email TEXT NOT NULL UNIQUE,
            password_hash TEXT NOT NULL,
            role TEXT NOT NULL,
            created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
            updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
          );
        `,
        `
          CREATE TABLE IF NOT EXISTS user_details (
            id INTEGER PRIMARY KEY AUTOINCREMENT,
            user_id INTEGER NOT NULL,
            first_name TEXT NOT NULL DEFAULT '',
            last_name TEXT NOT NULL DEFAULT '',
            street TEXT,
            house_number TEXT,
            zip TEXT,
            city TEXT,
            FOREIGN KEY (user_id) REFERENCES users(id) ON DELETE CASCADE
          );
        `,
        `
          CREATE TABLE IF NOT EXISTS bazaars (
            id INTEGER PRIMARY KEY AUTOINCREMENT,
            organizer_id INTEGER NOT NULL,
            start_date DATE NOT NULL,
            max_sellers INTEGER NOT NULL,
            max_items_per_seller INTEGER NOT NULL,
            commission REAL NOT NULL,
            starting_fee REAL NOT NULL,
            min_item_price REAL NOT NULL,
            max_sellernumbers_per_selleruser INTEGER NOT NULL DEFAULT 1,
            registration_closed BOOLEAN DEFAULT FALSE,
            created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
            updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
            FOREIGN KEY (organizer_id) REFERENCES users(id) ON DELETE CASCADE
          );
        `,
        `
          CREATE TABLE IF NOT EXISTS items (
            id INTEGER PRIMARY KEY AUTOINCREMENT,
            seller_id INTEGER NOT NULL,
            bazaar_id INTEGER NOT NULL,
            name TEXT NOT NULL,
            size TEXT,
            price REAL NOT NULL,
            sold BOOLEAN DEFAULT FALSE,
            created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
            updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
            FOREIGN KEY (seller_id) REFERENCES users(id) ON DELETE CASCADE,
            FOREIGN KEY (bazaar_id) REFERENCES bazaars(id) ON DELETE CASCADE
          );
        `,
        `
          CREATE TABLE IF NOT EXISTS seller_numbers (
            id INTEGER PRIMARY KEY AUTOINCREMENT,
            seller_id INTEGER NOT NULL,
            bazaar_id INTEGER NOT NULL,
            seller_number INTEGER NOT NULL,
            verified BOOLEAN DEFAULT FALSE,
            created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
            FOREIGN KEY (seller_id) REFERENCES users(id) ON DELETE CASCADE,
            FOREIGN KEY (bazaar_id) REFERENCES bazaars(id) ON DELETE CASCADE,
            UNIQUE (seller_id, bazaar_id, seller_number)
          );
        `,
        `
          CREATE TABLE IF NOT EXISTS logs (
            id INTEGER PRIMARY KEY AUTOINCREMENT,
            user_id INTEGER NOT NULL,
            role TEXT NOT NULL,
            action TEXT NOT NULL,
            created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
            FOREIGN KEY (user_id) REFERENCES users(id) ON DELETE CASCADE
          );
        `,
        `
          CREATE TABLE IF NOT EXISTS sessions (
            id INTEGER PRIMARY KEY AUTOINCREMENT,
            user_id INTEGER NOT NULL,
            token TEXT NOT NULL,
            expires_at TIMESTAMP NOT NULL,
            FOREIGN KEY (user_id) REFERENCES users(id) ON DELETE CASCADE
          );
        `,
      ];

      tableDefinitions.forEach((query) => tx.executeSql(query));
    }
  },

  async insert(table, data) {
    if (this.isCordova) {
      const placeholders = Object.keys(data).map(() => '?').join(', ');
      const keys = Object.keys(data).join(', ');
      const values = Object.values(data);

      const sql = `INSERT INTO ${table} (${keys}) VALUES (${placeholders})`;

      return this.query(sql, values);
    } else {
      return new Promise((resolve, reject) => {
        const transaction = this.db.transaction(table, 'readwrite');
        const store = transaction.objectStore(table);

        const request = store.add(data);
        request.onsuccess = () => resolve(request.result);
        request.onerror = (error) => reject(error);
      });
    }
  },

  async update(table, id, data) {
    if (this.isCordova) {
      const updates = Object.keys(data).map((key) => `${key} = ?`).join(', ');
      const values = [...Object.values(data), id];

      const sql = `UPDATE ${table} SET ${updates} WHERE id = ?`;

      return this.query(sql, values);
    } else {
      return new Promise((resolve, reject) => {
        const transaction = this.db.transaction(table, 'readwrite');
        const store = transaction.objectStore(table);

        const request = store.get(id);
        request.onsuccess = () => {
          const record = request.result;
          if (record) {
            const updatedRecord = { ...record, ...data };
            const updateRequest = store.put(updatedRecord);
            updateRequest.onsuccess = () => resolve(updateRequest.result);
            updateRequest.onerror = (error) => reject(error);
          } else {
            reject(new Error(`Record with id ${id} not found`));
          }
        };
        request.onerror = (error) => reject(error);
      });
    }
  },

  async delete(table, id) {
    if (this.isCordova) {
      const sql = `DELETE FROM ${table} WHERE id = ?`;
      return this.query(sql, [id]);
    } else {
      return new Promise((resolve, reject) => {
        const transaction = this.db.transaction(table, 'readwrite');
        const store = transaction.objectStore(table);

        const request = store.delete(id);
        request.onsuccess = () => resolve(request.result);
        request.onerror = (error) => reject(error);
      });
    }
  },

  async select(table, conditions = {}) {
    if (this.isCordova) {
      const keys = Object.keys(conditions).map((key) => `${key} = ?`).join(' AND ');
      const values = Object.values(conditions);

      const sql = keys ? `SELECT * FROM ${table} WHERE ${keys}` : `SELECT * FROM ${table}`;

      return this.query(sql, values);
    } else {
      return new Promise((resolve, reject) => {
        const transaction = this.db.transaction(table, 'readonly');
        const store = transaction.objectStore(table);

        const request = store.getAll();
        request.onsuccess = () => resolve(request.result.filter((item) => {
          return Object.entries(conditions).every(([key, value]) => item[key] === value);
        }));
        request.onerror = (error) => reject(error);
      });
    }
  },

  query(sql, params = []) {
    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        tx.executeSql(sql, params, (_, results) => resolve(results), (_, error) => reject(error));
      });
    });
  },
};

export default database;
