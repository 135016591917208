<template>
  <div class="container">
    <h1>Search Bazaars</h1>
    <input v-model="searchTerm" @input="searchBazaars" placeholder="Search by organizer or date" class="form-control mb-3" />
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Start Date</th>
          <th>Organizer</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="bazaar in bazaars" :key="bazaar.id">
          <td>{{ bazaar.start_date }}</td>
          <td>{{ bazaar.organizer_email }}</td>
          <td>
            <button @click="requestSellerNumber(bazaar)" class="btn btn-primary btn-sm">Request Seller Number</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: '',
      bazaars: [],
    };
  },
  methods: {
    async searchBazaars() {
      // Search bazaars from the API
    },
    async requestSellerNumber(bazaar) {
      // Request seller number
    },
  },
};
</script>
