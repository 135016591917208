<template>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    ref="modal"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div :class="['modal-header', alertTypeClass]">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          {{ message }}
        </div>
        <div class="modal-footer">
          <button
            v-if="showCancel"
            type="button"
            class="btn btn-secondary"
            @click="close"
          >
            {{ cancelText }}
          </button>
          <button
            v-if="showConfirm"
            type="button"
            :class="['btn', confirmButtonClass]"
            @click="confirm"
          >
            {{ confirmText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
export default {
  name: "MessageModal",
  props: {
    title: { type: String, default: "Message" },
    message: { type: String, required: true },
    alertType: { type: String, default: "info" }, // "info", "success", "warning", "danger"
    showCancel: { type: Boolean, default: true },
    showConfirm: { type: Boolean, default: true },
    cancelText: { type: String, default: "Cancel" },
    confirmText: { type: String, default: "Confirm" },
    confirmButtonClass: { type: String, default: "btn-primary" },
  },
  computed: {
    alertTypeClass() {
      const alertClasses = {
        info: "bg-info text-white",
        success: "bg-success text-white",
        warning: "bg-warning text-dark",
        danger: "bg-danger text-white",
      };
      return alertClasses[this.alertType] || "bg-info text-white";
    },
  },
  methods: {
    open() {
      const modal = new Modal(this.$refs.modal);
      modal.show();
    },
    close() {
      const modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
      this.close();
    },
  },
};
</script>

<style scoped>
.bs-modal-zindex : 2000;
</style>