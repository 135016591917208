<template>
  <div class="container">
    <h2 class="text-center">Persönliche Daten</h2>
    <form @submit.prevent="updatePersonalDetails" class="mt-4">
      <div class="row g-3">
        <!-- Personal Data -->
        <div class="col-md-6">
          <label for="firstName" class="form-label">Vorname</label>
          <input v-model="formData.first_name" type="text" id="firstName" class="form-control" required />
        </div>
        <div class="col-md-6">
          <label for="lastName" class="form-label">Nachname</label>
          <input v-model="formData.last_name" type="text" id="lastName" class="form-control" required />
        </div>
        <div class="col-md-6">
          <label for="email" class="form-label">E-Mail</label>
          <input v-model="formData.email" type="email" id="email" class="form-control" required />
        </div>
        <div class="col-md-6">
          <label for="phoneNumber" class="form-label">Telefonnummer</label>
          <input v-model="formData.phone_number" type="text" id="phoneNumber" class="form-control" />
        </div>
        <div class="col-md-6">
          <label for="street" class="form-label">Straße</label>
          <input v-model="formData.street" type="text" id="street" class="form-control" />
        </div>
        <div class="col-md-3">
          <label for="houseNumber" class="form-label">Hausnummer</label>
          <input v-model="formData.house_number" type="text" id="houseNumber" class="form-control" />
        </div>
        <div class="col-md-3">
          <label for="zip" class="form-label">PLZ</label>
          <input v-model="formData.zip" type="text" id="zip" class="form-control" />
        </div>
        <div class="col-md-6">
          <label for="city" class="form-label">Stadt</label>
          <input v-model="formData.city" type="text" id="city" class="form-control" />
        </div>

        <!-- Submit -->
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-primary">Daten speichern</button>
        </div>
      </div>
    </form>

    <!-- Message Modal -->
    <MessageModal
      ref="personalDetailsModal"
      :title="modalTitle"
      :message="modalMessage"
      :alertType="modalAlertType"
      confirmText="OK"
      :showCancel="false"
      @confirm="closeModal"
    />
  </div>
</template>

<script>
import { 
  fetchUserDetails, 
  updateUserDetails 
} from "@/api-connectors/user";

import MessageModal from "@/components/MessageModal.vue";

export default {
  components: {
    MessageModal,
  },
  data() {
    return {
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        street: "",
        house_number: "",
        phone_number: "",
        zip: "",
        city: "",
      },
      modalTitle: "",
      modalMessage: "",
      modalAlertType: "info", // Default alert type
    };
  },
  methods: {
    async loadUserDetails() {
      try {
        const authToken = this.$store.getters.getAuthToken;
        const result = await fetchUserDetails(authToken);

        if (result.success) {
          this.formDetails = result.userDetails;
        } else {
          // Configure and open the modal for error message
          this.modalTitle = "Fehler";
          this.modalMessage = result.message || "Fehler beim Laden der Benutzerdetails.";
          this.modalAlertType = "danger";
          this.$refs.personalDetailsModal.open(); // Updated modal reference
        }
      } catch (error) {
        // Show server connection error in modal
        this.modalTitle = "Fehler";
        this.modalMessage = "Fehler beim Verbinden mit dem Server.";
        this.modalAlertType = "danger";
        this.$refs.personalDetailsModal.open(); // Updated modal reference
        console.error("Error loading user details:", error);
      }
    },

    async updatePersonalDetails() {
      try {
        const authToken = this.$store.getters.getAuthToken;
        const result = await updateUserDetails(authToken, this.formDetails);

        if (result.success) {
          // Show success message in modal
          this.modalTitle = "Erfolg";
          this.modalMessage = "Details erfolgreich aktualisiert.";
          this.modalAlertType = "success";
        } else {
          // Configure and open the modal for error message
          this.modalTitle = "Fehler";
          this.modalMessage = result.message || "Fehler beim Speichern der Details.";
          this.modalAlertType = "danger";
        }

        this.$refs.personalDetailsModal.open();
      } catch (error) {
        // Show server connection error in modal
        this.modalTitle = "Fehler";
        this.modalMessage = "Fehler beim Verbinden mit dem Server.";
        this.modalAlertType = "danger";
        this.$refs.personalDetailsModal.open();
        console.error("Error updating user details:", error);
      }
    },
    closeModal() {
      console.log("Modal closed");
    },
  },
  async mounted() {
    await this.loadUserDetails();
  },
};
</script>

<style scoped>
/* Add specific styles for the personal data form here */
form {
  margin: 0 auto;
  max-width: 600px;
}
</style>
